[data-theme="light"]{
  --background-1: #f0f2f5;
  --background-2: #D7D7D7;
  --background-3: #b7b7b7;
  --accent-1: rgb(131, 17, 131);
  --accent-1-025: rgba(131, 17, 131,0.25);
  --accent-2: rgb(179, 152, 5);
  --text-1: black;
  --text-2: white;
}
[data-theme="dark"]{
  --background-1: #18191A;
  --background-2: #242526;
  --background-3: #0F1317;
  --accent-1: rgb(131, 17, 131);
  
  --accent-1-025: rgba(131, 17, 131,0.25);
  --accent-2: rgb(179, 152, 5);
  --text-1: white;
  --text-2: black;
}

html, body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 1.1em;
  background-color: var(--background-1);
  color: var(--text-1);
}
.button{
  background-color: var(--background-2);
  color: var(--text-1);
  border: 10px solid var(--background-2);
  border-radius: 5px;
}
.button:hover{
  color: var(--accent-1);
  text-decoration: none;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.navcolour{
  background-color: var(--background-2);
}
.form-control:focus{
  border-color: var(--accent-1);
  box-shadow: 0 0 0 .2rem var(--accent-1-025);
}
.nav-link{
  color: var(--text-1) !important;
  text-align: center;
}
.nav-link:hover{
  color: var(--accent-1) !important;
}

.card {
  background-color: var(--background-2);
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


.typeSec{
  display: flex;
  justify-content: center;
  width: 17%;
}
.ShopIcon{
  width: auto;
  height: 15px;
  margin: auto;
}

.hidden {
  display: none;
}
.pagination{
  width: max-content;
  margin: auto;
  max-width: 100%;
  overflow: clip;
}
.product{
  color: var(--text-1) !important;
  text-decoration: none;
  position: relative;
}
.form-group {
    margin:0;
    padding:20px ;
}

.form-control-search {
  	padding: 0px 10px 0 20px;
  	color: #333;
  	font-size: 28px;
  	font-weight: 500;
    border: 3px solid #555;
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height:60px;
    height: auto;
    border-radius: 50px 0  0 50px !important;
}

.jumbotron{
  background:none
}

.form-control-search :focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: transparent;
}

#searchbtn{ 
	border:0;
	padding: 0px 10px;
	color: #fff;
	background:#888;
	font-size: 27px;
	font-weight: 500;
	border: 3px solid #555;
	border-left: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	min-height:60px;
	height: auto;
	border-radius: 0 50px 50px 0 !important;
}

.Search{
  background-image: url('marketbg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (orientation:portrait){
  .Search{
    background-size: auto 100%;
  }
}

.xyz{
  border-left: 1px black solid;
  width: 80%;
  padding:0;
}
.browseProds{
  display: flex;
}
.xyx{
  width:100%;
  padding:0;
}

@media screen and (max-width:1224px){
  .xyz{width:100%}
  .browseProds{display: block;}
  .product2{width: 100% !important;}
}

.form-visable{
  display: grid;
  justify-content: center;
}

.form-visable-mob{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shops tr{
  display: flex;
}
.shops tc{
  display: flex;
  border: 1px solid grey;
  border-collapse: collapse;
}



/*Simple css to style it like a toggle switch*/
.theme-switch-wrapper {
  display: flex;
  align-items: center;
}
.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

input:checked + .slider {
  background-color: var(--accent-1);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.browseCheckboxes{
  grid-column: 4 / span 1;
}

.browseShopLabels{
  grid-column: 1 / span 3;
}

.form-control-search-2{
  padding: 0px 10px 0 20px;
  color: #333;
  font-weight: 500;
  border: 3px solid #555;
  box-shadow: none;
  height: auto;
  border-radius: 50px !important;
}

.uwu{
  background-color: var(--background-1);
}

.open{
  z-index: 999;
  top: 84.5px;
  left: 0;
}
#myNavbar{
  position: absolute;
  width: 100%;
  transition:left 0.1s linear;
  background-color: var(--background-1);
  height: calc(100vh - 84.5px);
}
.closed{
  left: -100vw;
  top: 84.5px;
}
.bottom{
  bottom: 0;
  position: absolute;
  width: 100%;
  margin-bottom: 10pt;
}

.hide-content{
  overflow: hidden;
}

.b-alt{
  background-color: var(--background-1);
  border-color: var(--background-1);
}

.b-alt:hover{
  background-color: var(--background-3);
  border-color: var(--background-3);
}

.profile:hover{
  color: var(--accent-1);
}
.shopList{
  margin-bottom: 10pt;
}
.button:disabled{
  background-color: var(--accent-2);
  border-color: var(--accent-2);
  color: var(--text-1) !important;
}


/* Tooltip text */
.button:disabled .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 10pt;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 110px;;
  left: 180px;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.button:disabled .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.button:disabled:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} 
.button:disabled .tooltiptext-mob{
  top: 209px;
  left: 50%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: var(--background-2);
  width: 60%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.showModal {
  display: block;
}

.hideModal {
  display: none;
}

.modal-content{
  background-color: var(--background-2);
  margin-top: 10pt;
  padding: 10pt;
}

.modal-top{
  display: flex;
}

.modal-close{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: auto;
  margin: auto 0 auto auto;
  background: none;
  border: none;
}

.b-close{
  border: 3px solid var(--text-1);
}

.b-save{
  background-color: var(--background-1);
  border:3px solid var(--background-1);
}

.b-close, .b-save{
  padding: 6pt 10pt;
}

.modal-footer{
  border-top: none;
}

.save-name-input{
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 10px;
  width:100%;
  background-color: var(--background-2);
  color: var(--text-1);
  padding:2px 8px;
}
.price-section {
	position: fixed;
	bottom: 0;
	border-top: 1px solid black;
	width: 100%;
	height: 50pt;
	display: flex;
	justify-content: center;
	flex-direction: row;
	padding-left: 10pt;
	background-color: var(--background-3);
}

.InfoSec{
  width: 80%;
  margin-left: auto;
}

.disp-ro{
  margin-bottom: 60pt;
}

.confirmation{
  position: fixed;
  bottom: 40pt;
  z-index: 999;
  margin: auto;
}